import call from "../cassandra-base/service/http";
import attendanceConstants from "./attendanceConstants";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  namespaced: true,
  state: {
    requests: [],
    logs: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    attendanceGetters: (state) => (val) => state[val],
  },
  actions: {
    getAttendanceRequests: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", attendanceConstants.requests(payload.employeeNo))
        .then((response) => {
          commit("MUTATE", {
            state: "requests",
            data: response.data.data.original.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
    getAttendanceLogs: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", attendanceConstants.logs, payload)
        .then((response) => {
          commit("MUTATE", {
            state: "logs",
            data: response.data.data.shift(),
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
    createRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", attendanceConstants.createRequest, payload)
        .then((response) => {
          dispatch("getAttendanceRequests", {employeeNo: AuthService.user.employee});
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Attendance created successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    requestApproval: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", attendanceConstants.requestApproval, payload)
        .then((response) => {
          // dispatch("getAttendanceRequests", AuthService.user.employee);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Approval request submitted successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};

<template>
  <v-container class="fade-transition">
    <AppraisalHeader class="mt-n1" />
    <v-card flat class="mt-3">
      <v-card-text>
        <span class="headline text-h6">My Appraisals </span>
        <v-row>
          <v-col cols="12" md="4" offset="8">
            <v-select
              v-model="selectedPeriod"
              prepend-inner-icon="filter_alt"
              color="primary"
              dense
              :items="periods"
              :item-text="(item) => item.code"
              :item-value="(item) => item.code"
              label="Filter by period"
              @change="filterAppraisalList"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="reviews">
              <template v-slot:item.id="{ item }">
                <span class="supervisor-review-table">{{ item["code"] }}</span>
              </template>
              <template v-slot:item.currentStage="{ item }">
                <span class="supervisor-review-table">{{
                  item["currentStage"]
                }}</span>
                <span class="mx-2">
                  <v-chip
                    v-show="item.status === 'Active'"
                    small
                    color="green"
                    dark
                  >
                    <span class="supervisor-review-table">{{
                      item.status
                    }}</span>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.employeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ MyScore(item) }}%</span
                >
              </template>
              <template v-slot:item.supervisorScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ SupervisorScore(item) }}%</span
                >
              </template>
              <template v-slot:item.overallScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ OverallScore(item) }}%</span
                >
              </template>
              <template v-slot:item.documentStatus="{ item }">
                <v-chip
                  v-show="item.documentStatus"
                  small
                  :color="getColor(item.documentStatus).color"
                  dark
                >
                  <span class="supervisor-review-table">{{
                    item["documentStatus"]
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn-toggle>
                  <!-- <v-btn
                    small
                    color="teal darken-1"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.documentStatus === 'Reviewed'"
                    :to="{ name: 'AppraisalReport', params: { code: item.code }}" >
                    report
                  </v-btn> -->
                  <v-btn
                    small
                    color="primary"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.documentStatus === 'Reviewed'"
                    @click="downloadReport(item.code)"
                  >
                    <v-icon left color="white"> mdi-download </v-icon>
                    report
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :disabled="
                      item.documentStatus !== 'Open' ||
                      currentReview.periodAPI.closed === true ||
                      item.status !== 'Active'
                    "
                    tile
                    :to="{
                      name: 'Appraisal Card',
                      params: { code: item.code },
                    }"
                  >
                    Appraise
                    <v-icon right color="white">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!--END My reviews-->
    </v-card>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import AppraisalMixin from "@/packages/cassandra-appraisal/modules/appraisalv3/AppraisalMixin";
//import AppraisalHeader from "./AppraisalHeader.vue";
const AppraisalHeader = () =>
  "@/packages/cassandra-appraisal/modules/appraisalv1/components/AppraisalHeader.vue";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  name: "AppraisalList",
  mixins: [RouterMixin, AppraisalMixin, FileMixin],
  components: {
    AppraisalHeader,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReviews");
      v.$store.dispatch("appraisal/getKpi");
    });
  },
  data: function () {
    return {
      headers: [
        { text: "Code", align: "", sortable: true, value: "id" },
        { text: "Stage", align: "", sortable: true, value: "currentStage" },
        {
          text: "My Score",
          align: "center",
          sortable: true,
          value: "employeeScore",
        },
        {
          text: "Supervisor Score",
          align: "center",
          sortable: true,
          value: "supervisorScore",
        },
        {
          text: "Final Score",
          align: "center",
          sortable: true,
          value: "overallScore",
        },
        { text: "Status", align: "", sortable: true, value: "documentStatus" },
        { text: "Actions", value: "action" },
      ],
      selectedPeriod: "",
      period: "",
    };
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
    reviews() {
      if (this.period !== "") {
        return this.$store.getters["appraisal/appraisalGetter"](
          "reviews"
        ).filter((a) => a.period === this.period);
      } else {
        return this.$store.getters["appraisal/appraisalGetter"]("reviews");
      }
    },
  },
  methods: {
    async downloadReport(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
    filterAppraisalList() {
      this.period = this.selectedPeriod;
    },
  },
  watch: {
    document() {
      this.downloadLink(this.document);
    },
  },
};
</script>

<!-- <style scoped>
@import "../styles/appraisal.css";
</style> -->

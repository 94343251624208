import attendanceRoutes from "./attendanceRoutes";
import attendanceStore from "./attendanceStore";
import attendanceLinks from "./attendanceLinks";

export default {
  install(Vue, options) {
    if (options.router) options.router.addRoute(...attendanceRoutes);

    if (options.store) {
      options.store.registerModule("Attendance", attendanceStore);
    }
    options.store.commit("Dashboard/ADD_LINK", attendanceLinks);
  },
};

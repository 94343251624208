<template>
  <v-container fluid class="mt-10">
    <v-card elevation="0" tile style="height: 600px">
      <v-card-title class="overline">
        <v-btn
          text
          @click="$router.back()"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        {{ period.code }} Appraisal Period
      </v-card-title>

      <v-skeleton-loader
        v-if="!isReviewDocument"
        type="image"
        width="100%"
        height="500"
      ></v-skeleton-loader>

      <v-text v-else class="pa-0" style="height: 500px; overflow: auto">
        <iframe
          v-if="isReviewDocument"
          width="100%"
          height="100%"
          style="border: none"
          :src="`data:application/pdf;base64,${reviewDocument}`"
        ></iframe>
      </v-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "closedPeriodAppraisal",
  // mixins: [FileMixin, DateMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const period = to.params.period;
      vm.$store.dispatch("appraisal/getArchivedReviewDocument", {
        employeeNo: vm.userData.employee,
        period,
      });
      const reviewDoc = vm.archivedReviewDocument[0].reviewDocNo;
      vm.$store.dispatch("appraisal/getReviewDocument", {
        reviewNo: reviewDoc,
      });
    });
  },
  computed: {
    selectedPeriod() {
      return this.$store.getters["appraisal/appraisalGetter"]("selectedPeriod");
    },
    period() {
      const periods =
        this.$store.getters["appraisal/appraisalGetter"]("periods");
      return periods.find((period) => period.code === this.selectedPeriod);
    },
    userData() {
      return this.$store.getters["auth/user"];
    },
    archivedReviewDocument() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "archivedReviewDocument"
      );
    },
    reviewDocument() {
      return this.$store.getters["appraisal/appraisalGetter"]("reviewDocument");
    },
    isReviewDocument() {
      // Check if the review document is valid
      if (this.reviewDocument && !this.reviewDocument.includes("Invalid")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

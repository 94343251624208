import AttendanceRequests from "./components/AttendanceRequests.vue";
import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";

export default [
  {
    path: "/attendance",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/attendance/requests",
      },
      {
        path: "requests",
        name: "AttendanceRequests",
        components: {
          view: AttendanceRequests,
        },
        meta: {
          middleware: Auth,
        },
      }
    ],
  },
];

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" My Attendance Requests "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Create Request ")])],1),_c(VDivider),_c(VTabs,{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab},[_vm._v(" "+_vm._s(tab === "Released" ? "Approved" : tab)+" ")])}),1),_c(VDivider),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredRequests,"search":_vm.search},scopedSlots:_vm._u([{key:"item.checkInTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTime(item.startDate)))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.startDate)))])]}},{key:"item.checkOutTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTime(item.endDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.endDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 'Open')?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.requestApproval(item)}}},[_vm._v(" Request Approval ")]):_vm._e()]}}])})],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{},[_vm._v(" Create Request "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","outlined":"","color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,[_c(VForm,{ref:"sendLink",on:{"submit":function($event){$event.preventDefault();return _vm.createRequest.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{staticClass:"mt-2",attrs:{"dense":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","type":"datetime-local","rules":_vm.rules.startDate,"label":"Start Date"},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","type":"datetime-local","rules":_vm.rules.endDate,"re":"","label":"End Date"},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","rules":_vm.rules.userLocation,"label":"Location"},model:{value:(_vm.formData.userLocation),callback:function ($$v) {_vm.$set(_vm.formData, "userLocation", $$v)},expression:"formData.userLocation"}})],1)],1),_c(VBtn,{attrs:{"rounded":"","type":"submit","color":"primary"}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card-text>
    <v-card elevation="0" tile class="main-card">
      <v-card-title class="overline header-title">
        <div class="caption">
          <span class="description">PERIOD: {{ stage.period }} </span>
        </div>
        <v-spacer />
        application status:
        <v-chip x-small dark class="ml-2" color="success">
          {{ currentApplication.status }}
        </v-chip>

        <v-btn
          v-if="currentApplication.status.toLowerCase() !== 'open'"
          rounded
          color="primary"
          elevation="9"
          small
          x-small
          class="ml-2"
          @click="approvalEntriesDialog = true"
          ><v-icon left> mdi-file-document-plus</v-icon>approval entries</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text v-if="isDataLoaded">
        <v-row v-if="period.highestLevel === 'Group'">
          <!-- groupings-->
          <v-col cols="12" md="4">
            <v-list subheader>
              <v-subheader>
                <span class="subheader-title">Groups</span>
                <v-spacer />
                <v-chip x-small dark class="blue" v-if="employeeGroups">
                  {{ employeeGroups.length }}
                </v-chip>
              </v-subheader>

              <v-list-item-group v-if="employeeGroups">
                <v-list-item
                  v-for="persp in employeeGroups"
                  :key="persp.groupID"
                  @click="setObjective(persp)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ persp.groupID }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon>mdi-menu-right</v-icon>
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="list-group-item">
                      {{ persp.groupDescription }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn icon>
                      <v-icon color="green lighten-2"
                        ><v-icon>mdi-menu-right</v-icon></v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                  <!-- Group edit btn -->
                  <!-- <v-list-item-icon
                    v-if="canEditOrInputGroups"
                    @click="editGroupHandler(persp)"
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-list-item-icon> -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- add custom Group -->

            <v-btn
              v-if="canEditOrInputGroups"
              rounded
              color="primary"
              elevation="9"
              small
              x-small
              @click="addGroupHandler()"
              ><v-icon left> mdi-file-document-plus</v-icon>Add Group</v-btn
            >
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <!-- Objective Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">{{
                  kpiObjectiveCaption ? kpiObjectiveCaption : "Objectives"
                }}</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="
                    intendedPerspSelection && intendedPerspSelection.objectives
                  "
                >
                  {{ intendedPerspSelection.objectives.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group
                v-if="
                  intendedPerspSelection && intendedPerspSelection.objectives
                "
              >
                <v-list-item
                  v-for="obj in intendedPerspSelection.objectives"
                  :key="obj.objectiveID"
                  @click="setKpi(obj)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ obj.objectiveID }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon color="green lighten-2"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="list-group-item"
                      v-text="obj.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <!-- Objective edit btn -->
                  <!-- <v-btn
                    v-if="stage && canEditOrInputObjectives"
                    small
                    text
                    class="mx-1"
                    fab
                    @click="editObjectiveHandler(obj)"
                  >
                    <v-icon>mdi-note-edit</v-icon>
                  </v-btn> -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- add Objectives-->
            <v-btn
              rounded
              color="primary"
              elevation="9"
              :loading="false"
              small
              x-small
              v-if="stage && canEditOrInputObjectives"
              @click="addObjectiveHandler"
              ><v-icon left>mdi-file-document-plus</v-icon>Add Objective</v-btn
            >
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <!-- KPI Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">Key Performance Indicators</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="intendedObjSelection && intendedObjSelection.indicators"
                >
                  {{ intendedObjSelection.indicators.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group
                v-if="intendedObjSelection && intendedObjSelection.indicators"
              >
                <v-list-item
                  v-for="kpi in intendedObjSelection.indicators"
                  :key="kpi.kpiCode"
                >
                  <!--  @click="setKpi(kpi)" -->
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ kpi.kpiCode }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon color="green lighten-2"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="list-group-item"
                      v-text="kpi.kpiDescription"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon color="green lighten-2">mdi-check-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <!-- KPI edit btn -->
                  <!-- {{ canEditOrInputKpis }} -->
                  <v-btn
                    v-if="canEditOrInputKpis && !kpi.global"
                    small
                    text
                    class="mx-1"
                    fab
                    @click="editKpisHandler(kpi)"
                  >
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canEditOrInputKpis && !kpi.global"
                    small
                    outlined
                    class="mx-1"
                    fab
                    color="red"
                    @click="handleAppLineDelete(kpi)"
                  >
                    <v-icon dark> mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- accept & add selected kpis -->

            <v-btn
              rounded
              color="primary"
              elevation="9"
              :loading="false"
              small
              x-small
              v-if="stage && canEditOrInputKpis"
              @click="addKpisHandler()"
            >
              <v-icon left> mdi-format-list-group-plus </v-icon>
              Add KPI's</v-btn
            >
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />
        </v-row>

        <!-- else highest level is Objectives -->
        <v-row v-if="period.highestLevel === 'Objective'">
          <!-- Objective Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">{{
                  kpiObjectiveCaption ? kpiObjectiveCaption : "Objectives"
                }}</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="employeeObjectives"
                >
                  {{ employeeObjectives.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group v-if="employeeObjectives">
                <v-list-item
                  v-for="obj in employeeObjectives"
                  :key="obj.objectiveID"
                  @click="setKpi(obj)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ obj.objectiveID }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon>mdi-menu-right</v-icon>
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="list-group-item"
                      v-text="obj.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <!-- Objective edit btn -->

                  <!-- <v-btn
                    v-if="stage && canEditOrInputObjectives"
                    small
                    text
                    class="mx-1"
                    fab
                    @click="editObjectiveHandler(obj)"
                  >
                    <v-icon>mdi-note-edit</v-icon>
                  </v-btn> -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- add Objectives-->
            <v-btn
              rounded
              color="primary"
              elevation="9"
              :loading="false"
              small
              x-small
              v-if="stage && canEditOrInputObjectives"
              @click="addObjectiveHandler"
              ><v-icon left>mdi-file-document-plus</v-icon>Add Objective</v-btn
            >
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <!-- KPI Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">Key Performance Indicators</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="intendedObjSelection && intendedObjSelection.indicators"
                >
                  {{ intendedObjSelection.indicators.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group
                v-if="intendedObjSelection && intendedObjSelection.indicators"
              >
                <v-list-item
                  v-for="kpi in intendedObjSelection.indicators"
                  :key="kpi.kpiCode"
                >
                  <!--  @click="setKpi(kpi)" -->
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ kpi.kpiCode }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon color="green lighten-2"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="list-group-item"
                      v-text="kpi.kpiDescription"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon color="green lighten-2">mdi-check-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <!-- KPI edit btn -->
                  <!-- {{ canEditOrInputKpis }} -->

                  <v-btn
                    v-if="canEditOrInputKpis && !kpi.global"
                    small
                    text
                    class="mx-1"
                    fab
                    @click="editKpisHandler(kpi)"
                  >
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canEditOrInputKpis && !kpi.global"
                    small
                    outlined
                    class="mx-1"
                    fab
                    color="red"
                    @click="handleAppLineDelete(kpi)"
                  >
                    <v-icon dark> mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- accept & add selected kpis -->

            <v-btn
              rounded
              color="primary"
              elevation="9"
              :loading="false"
              small
              x-small
              v-if="stage && canEditOrInputKpis"
              @click="addKpisHandler()"
            >
              <v-icon left> mdi-format-list-group-plus </v-icon>
              Add KPI's</v-btn
            >
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />
        </v-row>
        <!-- end -->
        <!-- <v-expand-transition> -->
        <div
          v-if="currentApplication.status.toLowerCase() !== 'open'"
          class="mt-4"
        >
          <v-divider></v-divider>
          <v-dialog v-model="approvalEntriesDialog" width="70%">
            <v-card>
              <v-card-title class="">
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  outlined
                  color="red"
                  small
                  @click="approvalEntriesDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <ApprovalEntries
                  :approvalEntries="approvalEntries"
                  v-if="currentApplication.status !== 'open'"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <!-- </v-expand-transition> -->
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              type="article"
              :loading="true"
              :rows="10"
              :headers="headers"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              type="article"
              :loading="true"
              :rows="10"
              :headers="headers"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              type="article"
              :loading="true"
              :rows="10"
              :headers="headers"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
        <v-spacer />
        <v-btn text small @click="gotoKpiListing" color="primary">
          <v-icon left> mdi-arrow-left </v-icon>
          Back to All Kpis
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- dialog to edit kpis -->
    <EditTemplateDialog
      :editTemplateDialog="editTemplateDialog"
      :entity="objectEntity"
      :editing="isEditing"
      @close-dialog="closeDialog"
      @add-item="addKpi"
      @edit-item="editKpi"
      ref="editTemplateDialog"
    />

    <!-- add|edit objectives Dialog -->
    <ObjectiveAddEditDialog
      :objectiveDialog="objectiveDialog"
      :entity="objectiveEntity"
      :editing="isEditing"
      @close-dialog="closeObjectiveDialog"
      @add-objective="addCustomObjective"
      @edit-objective="editObjective"
    />
    <!-- add|Edit Groups -->
    <group-add-edit-dialog
      :groupDialog="groupDialog"
      :entity="groupEntity"
      :editing="isEditing"
      @close-dialog="closeGroupDialog"
      @add-group="addCustomGroup"
      @edit-group="editGroup"
    />
  </v-card-text>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import EditTemplateDialog from "./EditTemplateDialog.vue";
import ObjectiveAddEditDialog from "./ObjectiveAddEditDialog.vue";
import GroupAddEditDialog from "./GroupAddEditDialog.vue";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

import ApprovalEntries from "./ApprovalEntries.vue";

export default {
  name: "Summary",
  components: {
    EditTemplateDialog,
    ObjectiveAddEditDialog,
    GroupAddEditDialog,
    ApprovalEntries,
  },
  mixins: [RouterMixin, FileMixin],
  data: () => ({
    selectedKpi: null,
    selectedPersp: null,
    selectedObject: null,
    intendedObjSelection: null,
    intendedPerspSelection: null,
    unsaved: false,
    summaryDialog: false,
    status: "",
    selfAttachment: null,
    reviewerAttachment: null,
    prevRoute: null,

    //kpi
    editTemplateDialog: false,
    isEditing: false,
    objectEntity: {},

    //objective
    objectiveEntity: {},
    objectiveDialog: false,

    //Groups
    groupEntity: {},
    groupDialog: false,

    template: {}, // temp app lines
    approvalEntriesDialog: false,
  }),

  mounted() {
    this.$store.dispatch("appraisal/getPeriods");
    this.$store.dispatch("appraisal/getRatingScale");
    this.$store.dispatch("appraisal/getTemplates");
    //get emp application (period, stage)
    this.$store.dispatch(
      "appraisal/getApplication",
      this.decodeRoute(this.$route.params.applicationNo)
    );
    //get approval entries
    this.$store.dispatch(
      "appraisal/getApprovalEntries",
      this.decodeRoute(this.$route.params.applicationNo)
    );
  },

  computed: {
    isDataLoaded() {
      return this.$store.getters["appraisal/appraisalGetter"]("isDataLoaded");
    },
    currentReview() {
      return this.$store.getters["appraisal/appraisalGetter"]("templates");
    },
    groups() {
      return this.currentReview ? this.currentReview.groups : [];
    },
    objectives() {
      let objectives = [];
      if (this.groups) {
        this.groups.forEach((group) => {
          objectives = [...objectives, ...group.objectives];
        });
        objectives = objectives.sort((a, b) =>
          a.sequenceID > b.sequenceID ? 1 : -1
        );
        return objectives;
      } else {
        return [];
      }
    },
    kpis() {
      const objective = this.objectives
        .filter((ob) => ob.objectiveID === this.$route.params.objective)
        .shift();
      return objective
        ? objective.indicators
            .sort((a, b) => (a.kpiID > b.kpiID ? 1 : -1))
            .map((kpi) => ({ ...kpi, selected: false })) // Add the selected prop control
        : [];
    },
    period() {
      const periods =
        this.$store.getters["appraisal/appraisalGetter"]("periods");

      return periods?.find(
        (period) => period.code === this.decodeRoute(this.$route.params.period)
      );
    },

    stage() {
      const periods =
        this.$store.getters["appraisal/appraisalGetter"]("periods");

      return (
        periods
          ?.find(
            (period) =>
              period.code === this.decodeRoute(this.$route.params.period)
          )
          ?.periodStages.find((stage) => stage.current === true) || {}
      );
    },
    stageEntities() {
      return this.stage.stageEntities.map((item) => ({
        entity: item.entity,
        description: item.description,
      }));
    },
    canEditOrInputGroups() {
      return this.stageEntities.some((e) => e.entity.toLowerCase() === "group");
    },
    canEditOrInputObjectives() {
      return this.stageEntities.some(
        (e) => e.entity.toLowerCase() === "objective"
      );
    },
    canEditOrInputKpis() {
      return this.stageEntities.some(
        (e) => e.entity.toLowerCase() === "indicator"
      );
    },
    userData() {
      return AuthService.user;
    },
    //custom objective caption
    kpiObjectiveCaption() {
      return this.userData.captions.kpiObjective_caption;
    },
    currentApplication() {
      return this.$store.getters["appraisal/appraisalGetter"]("application");
    },
    employeeGroups() {
      return this.currentApplication
        ? this.currentApplication?.employeeGroups
        : [];
    },
    employeeObjectives() {
      return this.currentApplication
        ? this.currentApplication?.employeeAppObjectives
        : [];
    },
    employeeKpis() {
      return this.currentApplication.employeeApplicationLines.map(
        ({
          documentNo,
          kpiCode,
          kpiDescription,
          lineNo,
          objectiveCode,
          groupCode,
          target,
          weight,
          global,
        }) => ({
          documentNo,
          kpiCode,
          kpiDescription,
          lineNo,
          objectiveCode,
          groupCode,
          target,
          weight,
          global,
        })
      );
    },
    approvalEntries() {
      return this.$store.getters["appraisal/appraisalGetter"]("approvalEntries")
        .data;
    },
  },

  methods: {
    gotoKpiListing() {
      if (this.$vuetify.breakpoint.smAndDown) {
        const el = this.$refs.listedIndicators;
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },

    setKpi: function (kpi) {
      if (!kpi) {
        return;
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        const el = this.$refs.ratingForm;
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      this.intendedObjSelection = { ...kpi };

      this.intendedObjSelection.indicators = this.employeeKpis.filter(
        (kpi) =>
          kpi.objectiveCode === this.intendedObjSelection.objectiveID &&
          kpi.kpiCode !== ""
      );

      if (
        JSON.stringify({ ...this.selectedKpi }) !==
        JSON.stringify({ ...this.selectedObject })
      ) {
        this.unsaved = true;
        return;
      }

      let selfComment = null;
      let reviewerComment = null;
      let selfAttachment = null;
      let reviewerAttachment = null;

      this.selectedKpi = {
        ...kpi,
        ...{
          reviewerComment: reviewerComment ? reviewerComment.Comment : "",
          selfComment: selfComment ? selfComment.Comment : "",
          selfAttachment: selfAttachment
            ? process.env.VUE_APP_FILE_API + selfAttachment.base64Attachment
            : "",
          reviewerAttachment: reviewerAttachment
            ? process.env.VUE_APP_FILE_API + reviewerAttachment.base64Attachment
            : "",
        },
      };

      this.selectedObject = { ...this.selectedKpi };
      this.$nextTick(() => {
        Event.$emit("parentAction", "employeeForm");
        Event.$emit("parentAction", "supervisorForm");
      });
    },

    setObjective: function (obj) {
      // if (this.$vuetify.breakpoint.smAndDown) {
      //   const el = this.$refs.ratingForm;
      //   el.scrollIntoView({ behavior: "smooth", block: "center" });
      // }
      this.intendedPerspSelection = { ...obj };
      // if (
      //   JSON.stringify({ ...this.selectedPersp }) !==
      //   JSON.stringify({ ...this.selectedObjCtrl })
      // ) {
      //   this.unsaved = true;
      //   return;
      // }
      this.intendedPerspSelection.objectives = this.employeeObjectives.filter(
        (appObjectives) =>
          appObjectives.groupID === this.intendedPerspSelection.groupID
      );

      let selfComment = null;
      let reviewerComment = null;
      let selfAttachment = null;
      let reviewerAttachment = null;

      this.selectedPersp = {
        ...obj,
        ...{
          reviewerComment: reviewerComment ? reviewerComment.Comment : "",
          selfComment: selfComment ? selfComment.Comment : "",
          selfAttachment: selfAttachment
            ? process.env.VUE_APP_FILE_API + selfAttachment.base64Attachment
            : "",
          reviewerAttachment: reviewerAttachment
            ? process.env.VUE_APP_FILE_API + reviewerAttachment.base64Attachment
            : "",
        },
      };

      this.selectedObject = { ...this.selectedKpi };
      this.$nextTick(() => {
        Event.$emit("parentAction", "employeeForm");
        Event.$emit("parentAction", "supervisorForm");
      });
    },

    discard: function () {
      this.selectedKpi = { ...this.selectedObject };
      this.unsaved = false;

      this.setKpi(this.intendedObjSelection);
    },

    submit: function () {
      this.summaryDialog = true;
    },

    closeSummaryDialog: function (val) {
      this.summaryDialog = val;
    },
    //add group
    addGroupHandler: function () {
      this.isEditing = false;
      this.groupEntity = {
        ...{
          // group props
          groupDescription: "",
          templateID: "",
          Weight: "",
          groupID: "",
          sequenceID: "",
          objectives: [], // default objectives
        },
      };
      this.groupDialog = true;
    },
    editGroupHandler: function (obj) {
      this.isEditing = true;
      this.groupEntity = { ...obj };
      this.groupDialog = true;
    },

    addCustomGroup: function (newGroup) {
      if (!this.isEditing) {
        newGroup.documentNo = this.currentApplication.no;
        this.$store.dispatch("appraisal/createEditGroupHandler", newGroup);

        this.closeGroupDialog();
      }
    },
    editGroup(updatedItem) {
      if (this.isEditing) {
        // get index
        const indexOfEditedItem = this.employeeGroups.findIndex(
          (item) => item.groupID === updatedItem.groupID
        );

        if (indexOfEditedItem !== -1) {
          updatedItem.lineNo =
            this.currentApplication?.employeeApplicationLines.filter(
              (line) => line.groupCode === updatedItem.groupID
            )[0].lineNo;

          //dispatch add group
          this.$store.dispatch("appraisal/createEditGroupHandler", updatedItem);

          this.closeGroupDialog();
        }
      }
    },

    //add objectives
    addCustomObjective: function (newObjective) {
      if (!this.isEditing) {
        newObjective.documentNo = this.currentApplication.no;

        this.$store.dispatch(
          "appraisal/createEditObjectivesHandler",
          newObjective
        );

        this.closeObjectiveDialog();
      }
    },

    /* fn handler to add objectives*/
    addObjectiveHandler: function () {
      this.isEditing = false;
      this.objectiveEntity = {
        ...{
          groupID: this.intendedPerspSelection
            ? this.intendedPerspSelection.groupID
            : "",
          description: "",
          templateCode: "",
          objective: "",
          sequenceID: "",
          indicators: [],
        },
      };
      this.objectiveDialog = true;
    },

    editObjectiveHandler: function (obj) {
      this.isEditing = true;
      this.objectiveEntity = { ...obj };
      this.objectiveDialog = true;
    },
    editObjective(updatedItem) {
      if (this.isEditing) {
        let indexOfEditedItem;
        if (this.period.highestLevel === "Group") {
          indexOfEditedItem = this.intendedPerspSelection.objectives.findIndex(
            (item) => item.objectiveID === updatedItem.objectiveID
          );
        } else {
          indexOfEditedItem =
            this.intendedObjSelection.objectiveID === updatedItem.objectiveID
              ? 0
              : -1;
        }

        if (indexOfEditedItem !== -1) {
          updatedItem.lineNo =
            this.currentApplication?.employeeApplicationLines.filter(
              (line) => line.objectiveCode === updatedItem.objectiveID
            )[0].lineNo;

          this.$store.dispatch(
            "appraisal/createEditObjectivesHandler",
            updatedItem
          );
          this.closeObjectiveDialog();
        }
      }
    },

    /* fn handler to add KPI's*/
    addKpisHandler: function () {
      this.isEditing = false;
      this.objectEntity = {
        ...{
          isUpdate:
            this.currentApplication?.employeeApplicationLines.find(
              (line) =>
                line.objectiveCode === this.intendedObjSelection.objectiveID
            ).kpiCode === "",
          objectiveCode: this.intendedObjSelection.objectiveID ?? "",
          lineNo: this.currentApplication?.employeeApplicationLines.filter(
            (line) =>
              line.objectiveCode === this.intendedObjSelection.objectiveID
          )[0].lineNo,
          groupCode: this.intendedObjSelection.groupID ?? "",
          kpiDescription: "",
          weight: "",
          target: "",
        },
      };

      this.editTemplateDialog = true;
    },

    /** fn edit KPI's*/
    editKpisHandler: function (kpi) {
      this.isEditing = true;
      this.objectEntity = { ...kpi };
      this.editTemplateDialog = true;
    },
    editKpi(updatedItem) {
      if (this.isEditing) {
        const indicatorIndex = this.intendedObjSelection.indicators.findIndex(
          (indicator) => indicator.kpiCode === updatedItem.kpiCode
        );

        if (indicatorIndex !== -1) {
          updatedItem.documentNo = this.currentApplication.no;
          updatedItem.groupCode = this.intendedObjSelection.groupID ?? "";

          this.$store.dispatch("appraisal/createEditKpiHandler", updatedItem);

          this.closeDialog();
        }
      }
    },

    addKpi(newItem) {
      if (!this.isEditing) {
        let objectiveIndex;
        if (this.period.highestLevel === "Group") {
          objectiveIndex = this.intendedPerspSelection.objectives.findIndex(
            (item) => item.objectiveID === newItem.objectiveCode
          );
        } else {
          objectiveIndex =
            this.intendedObjSelection.objectiveID === newItem.objectiveCode
              ? 0
              : -1;
        }

        if (objectiveIndex !== -1) {
          newItem.documentNo = this.currentApplication.no;

          this.$store.dispatch("appraisal/createEditKpiHandler", newItem);
          this.closeDialog();
        }
      }
    },

    //close dialog
    closeDialog() {
      this.editTemplateDialog = false;
    },
    closeObjectiveDialog() {
      this.objectiveDialog = false;
    },
    closeGroupDialog() {
      this.groupDialog = false;
    },
    closeApprovalEntriesDialog() {
      this.approvalEntriesDialog = false;
    },
    handleAppLineDelete(appLine) {
      this.$confirm.show({
        title: "",
        text: `Are you sure you want to delete  ${appLine.kpiCode} from your application?`,
        onConfirm: async () => {
          await this.$store.dispatch(
            "appraisal/deleteApplicationLine",
            appLine
          );

          await this.$store.dispatch(
            "appraisal/getApplication",
            this.decodeRoute(this.$route.params.applicationNo)
          );
        },
      });
    },
  },

  watch: {
    currentApplication() {
      if (this.period.highestLevel === "Group")
        this.setObjective(this.employeeGroups[0]);

      if (this.period.highestLevel === "Objective")
        this.setKpi(this.employeeObjectives[0]);
    },
  },
};
</script>

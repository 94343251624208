export default {
  computed: {
    employees: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    ledgerEntries: function () {
      return this.$store.getters["leaveSetup/setupGetter"](
        "ledgerEntries"
      ).filter((entry) => {
        if (this.leave_type === null) {
          return entry.taken !== 0;
        } else {
          return entry.leave_code === this.leave_type;
        }
      });
    },
    leaveAllocations: function () {
      return this.$store.getters["leaveSetup/setupGetter"]("allocations");
    },
    allocations: function () {
      return this.leaveAllocations
        ? this.leaveAllocations.map((balance) => {
            switch (balance.leave_code) {
              case "ANNUAL":
                balance.avatarColor = "light-blue lighten-4";
                balance.iconColor = "light-blue darken-4";
                balance.textColor = "light-blue--text text--darken-4";
                balance.icon = "date_range";
                break;
              case "SICK":
                balance.avatarColor = "cyan lighten-4";
                balance.iconColor = "cyan darken-4";
                balance.textColor = "cyan--text text--darken-4";
                balance.icon = "sick";
                break;
              case "MATERNITY" || "PATERNITY":
                balance.avatarColor = "pink lighten-5";
                balance.iconColor = "pink darken-4";
                balance.textColor = "pink--text text--darken-4";
                balance.icon = "child_friendly";
                break;

              default:
                balance.avatarColor = "primary lighten-4";
                balance.iconColor = "primary darken-4";
                balance.textColor = "primary--text primary--darken-4";
                balance.icon = "assignment_turned_in";
                break;
            }
            return balance;
          })
        : [];
    },
    employee: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees").filter(
        (employee) => employee.number === this.data.hand_over_to
      )[0];
    },
    approvers: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    leaveTypes: function () {
      const userGender = this.userData.gender;
      return this.$store.getters["leaveSetup/setupGetter"]("leaveTypes").filter(
        (leaveType) =>
          this.leaveAllocations.find(
            (allocation) => allocation.leave_code === leaveType.code
          )
      );
    },
    leaveTypesAll: function () {
      return this.$store.getters["leaveSetup/setupGetter"]("leaveTypes");
    },
    leavePeriod: function () {
      return this.$store.getters["leaveSetup/setupGetter"](
        "leavePeriod"
      ).filter((period) => {
        return period.current;
      });
    },
    leaveEmployees: function () {
      const employees =
        this.$store.getters["Setup/setupGetter"]("leaveEmployees");
      return employees.filter(
        (employee) => employee.number !== this.userData.employee
      );
    },
    leaveEmployeeAll: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    leaveEmployee: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees").filter(
        (employee) => employee.number === this.userData.employee
      );
    },
  },
  methods: {
    getSetup() {
      this.$store.dispatch("leaveSetup/getLeaveTypes");
      this.$store
      .dispatch("leaveSetup/getLeavePeriod")
      .then((response) => {
        this.$store.commit("leaveSetup/MUTATE", {
          state: "leavePeriod",
          data: response.data.data,
        });

        const leavePeriod = response.data.data.filter((period) => {
          return period?.current;
        });

        if (leavePeriod.length) {
          this.$store.dispatch("leaveSetup/getAllocations", {
            employee_no: this.userData.employee,
            leave_period: leavePeriod[0].code,
          });
        }
      })
      .catch((error) => {
        console.log("leave period error", error.response.data.message);
      });
      this.$store.dispatch(
        "leaveSetup/getLedgerEntries",
        this.userData?.employee
      );
      this.$store.dispatch("leaveSetup/getHandoverSetup");
    },
  },
  
};

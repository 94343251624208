import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import { membershipRoutes } from "./Modules/membership";

export default [
  {
    path: "/Reports",
    component: AppDashboard,
    children: [membershipRoutes],
  },
];

<template>
  <v-card-text>
    <v-card elevation="0" tile class="main-card">
      <v-card-title class="overline header-title">
        <div class="caption">
          <span class="description">Appraisal Guidelines </span>
        </div>
        <v-chip x-small dark class="ml-2" color="primary">{{ period }}</v-chip>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="isDataLoaded && appraisalGuideLines">
        <v-card-text
          v-if="getAppraisalGuidelines() !== ''"
          v-html="getAppraisalGuidelines()"
        />
        <v-card-text v-else> No Guidelines Found </v-card-text>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12" md="12">
            <v-skeleton-loader
              type="card-heading, divider, list-item-three-line, card-heading"
              :loading="true"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
export default {
  name: "Guidelines",
  mixins: [RouterMixin],
  data: () => ({}),

  mounted() {
    this.$store.dispatch("appraisal/getPeriods");
    //fetch the guideline
    this.$store.dispatch("appraisal/getAppraisalGuideLine");
  },

  computed: {
    isDataLoaded() {
      return this.$store.getters["appraisal/appraisalGetter"]("isDataLoaded");
    },
    appraisalGuideLines() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalGuideline"
      )[0];
    },
    period() {
      return this.decodeRoute(this.$route.params.period);
    },
  },

  methods: {
    getAppraisalGuidelines() {
      return atob(this.appraisalGuideLines.appraisalGuidelines);
    },
  },

  watch: {},
};
</script>

<template>
  <v-card class="mx-auto" flat>
    <v-card-title>
      My Attendance Requests
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        dense
        outlined
        hide-details
      ></v-text-field>
      <v-spacer />
      <v-btn small color="primary" @click="dialog = true">
        Create Request
      </v-btn>
    </v-card-title>
    <v-divider />
    <!-- Tabs for filtering -->
    <v-tabs v-model="selectedTab">
      <v-tab v-for="tab in tabs" :key="tab">
        {{ tab === "Released" ? "Approved" : tab }}
      </v-tab>
    </v-tabs>
    <v-divider />
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredRequests"
        :search="search"
        class="elevation-0"
      >
       <template v-slot:item.checkInTime="{ item }">
          <span>{{ getTime(item.startDate) }}</span>
        </template>
        <template v-slot:item.startDate="{ item }">
          <span>{{ getDate(item.startDate) }}</span>
        </template>
       <template v-slot:item.checkOutTime="{ item }">
          <span>{{ getTime(item.endDate) }}</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span>{{ getDate(item.endDate) }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)">
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.status === 'Open'"
            color="primary"
            class="mr-2"
            elevation="0"
            @click="requestApproval(item)"
          >
            Request Approval
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="">
          Create Request
          <v-spacer></v-spacer>
          <v-btn fab outlined color="red" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="sendLink" v-model="isValid" @submit.prevent="createRequest">
            <v-row dense justify="space-between" class="mt-2">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="datetime-local"
                  :rules="rules.startDate"
                  v-model="formData.startDate"
                  label="Start Date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="datetime-local"
                  :rules="rules.endDate"
                  v-model="formData.endDate"
                  re
                  label="End Date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  dense
                  :rules="rules.userLocation"
                  v-model="formData.userLocation"
                  label="Location"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn rounded type="submit" color="primary">Save</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "AttendanceRequests",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      // v.$store.dispatch("Attendance/getAttendanceRequests", {
      //   employeeNo: v.userData.employee,
      // });
    });
  },

  data() {
    return {
      search: "",
      dialog: false,
      formData: {
        employeeNo: "",
        startDate: "",
        endDate: "",
        userLocation: "",
        location: null,
      },
      selectedTab: 0,
      tabs: ["Open", "Pending Approval", "Released"],
      today: new Date().toISOString().split("T")[0],
      isValid: false,
      location: null,
    };
  },

  mounted() {
    this.getGeolocation();
    this.fetchRequest();
  },

  computed: {
    userData() {
      return AuthService.user;
    },
    requests() {
      return this.$store.getters["Attendance/attendanceGetters"]("requests");
    },
    filteredRequests() {
      let tabName = this.tabs[this.selectedTab];
      return this.requests?.filter((request) => request.status === tabName);
    },
    headers() {
      return [
        {
          text: "Request Number",
          align: "start",
          sortable: true,
          value: "entryNo",
        },
        {
          text: "Employee Name",
          align: "start",
          sortable: true,
          value: "employeeName",
        },
        {
          text: "Check-in Time",
          align: "start",
          sortable: true,
          value: "checkInTime",
        },
        {
          text: "Start Date",
          align: "start",
          sortable: true,
          value: "startDate",
        },
        {
          text: "Check-out Time",
          align: "start",
          sortable: true,
          value: "checkOutTime",
        },
        {
          text: "End Date",
          align: "start",
          sortable: true,
          value: "endDate",
        },
        {
          text: "Location",
          align: "start",
          sortable: true,
          value: "location",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return {
        startDate: [
          (value) =>
            !value ||
            value < this.today ||
            "Start Date should not be a future date",
          (value) => !!value || "Start Date is required"
        ],
        endDate: [
          (value) =>
            !value ||
            value < this.today ||
            "Start Date should not be a future date",
          (value) => !!value || "End Date is required"
        ],
        userLocation: [
          (value) => !!value || "Location is Required.",
        ],
      };
    },
  },
  methods: {
    getDate(date) {
      return helper.getDateFormat(date);
    },
    getStatusColor(status) {
      return helper.getColor(status).color;
    },

    getTime(date){
      let time = new Date(date).toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit', hour12: true });
      return time
    },

    createRequest() {
      /** validate form */
      if (!this.isValid) {
        this.$refs.sendLink.validate();
      } else {
        this.formData.employeeNo = this.userData.employee;
        this.formData.location = this.location;

        this.$store.dispatch("Attendance/createRequest", { ...this.formData });
        this.dialog = false;
      }
    },

    requestApproval(request) {
      this.$store.dispatch("Attendance/requestApproval", {
        entryNo: request.entryNo,
      });
      this.fetchRequest();
    },

    getGeolocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.locationFound,
          this.error
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },

    locationFound(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.location = { latitude, longitude };
    },

    fetchRequest() {
      this.$store.dispatch("Attendance/getAttendanceRequests", {
        employeeNo: this.userData.employee,
      });
    }
  },
};
</script>

import auth from "./views/auth";
import Landing from "./views/components/Landing";

export default [
  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "",
        redirect: "/auth/login",
      },
      {
        path: "login",
        name: "login",
        component: Landing,
      },
      {
        path: "setPassword/:token",
        name: "setPassword",
        component: ()=>import("./views/components/SetPassword"),
      },
      {
        path: "reset",
        name: "reset",
        component: ()=>import("./views/components/ForgotPassword"),
      },
      {
        path: "signup",
        name: "Signup",
        component: ()=>import("./views/components/Signup"),
      },
      {
        path: "activate",
        name: "Credentials",
        component: ()=>import("./views/components/Credentials.vue"),
      },
      {
        path: "verify/otp",
        name: "OtpVerify",
        component: ()=>import("./views/components/OtpVerify.vue"),
      },
    ],
  },
];

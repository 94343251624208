<template>
  <v-container fluid>
    <AppBar
      :isApprover="isApprover"
      :path="path"
      :leaveapprovalSummary="leaveapprovalSummary"
    />
    <v-row>
      <v-col cols="12" md="4">
        <div>
          <v-text-field
            v-model="listFilter"
            prepend-inner-icon="filter_alt"
            solo
            dense
            label="filter"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <v-badge
          bordered
          overlap
          :content="totalOpen"
          color="secondary"
          offset-x="19"
          offset-y="16"
          class="mx-2 px-2"
        >
          <v-btn class="primary text-capitalize"> Total Open </v-btn>
        </v-badge>
      </v-col>

      <v-col cols="12" md="2">
        <v-badge
          bordered
          overlap
          :content="totalReview"
          color="secondary"
          offset-x="19"
          offset-y="16"
          class="mx-2 px-2"
        >
          <v-btn class="primary text-capitalize"> Total Review </v-btn>
        </v-badge>
      </v-col>
      <v-col cols="12" md="2">
        <v-badge
          bordered
          overlap
          :content="totalPosted"
          color="secondary"
          offset-x="19"
          offset-y="16"
          class="mx-2 px-2"
        >
          <v-btn class="primary text-capitalize"> Total Posted </v-btn>
        </v-badge>
      </v-col>
      <v-col cols="12" md="2">
        <v-badge
          bordered
          overlap
          :content="totalRejected"
          color="secondary"
          offset-x="19"
          offset-y="16"
          class="mx-2 px-2"
        >
          <v-btn class="primary text-capitalize"> Total Rejected </v-btn>
        </v-badge>
      </v-col>
    </v-row>
    <div style="height: 100%">
      <LeaveCalender v-if="isCalender" :isApprover="isApprover" />
      <ApprovalSummary
        :isApprover="isApprover"
        :leaveapprovalSummary="leaveapprovalSummary"
        @fetchedApplications="handleApplications"
      />
    </div>
    <LeaveSheet />
  </v-container>
</template>

<script>
import LeaveCalender from "./LeaveCalender";
import ApprovalSummary from "./ApprovalSummary";
import LeaveSheet from "../components/LeaveSheet";
import AppBar from "../components/AppBar";
import { SetupMixin } from "../../../mixins";
import LeaveMethods from "../LeaveMethods";
export default {
  name: "LeaveSummary",
  mixins: [SetupMixin],
  components: {
    LeaveCalender,
    ApprovalSummary,
    LeaveSheet,
    AppBar,
  },
  data: function () {
    return {
      dateRange: [],
      actionsMenu: null,
      isApprover: this.$router.currentRoute.meta.isApprover,
      leaveapprovalSummary: this.$router.currentRoute.meta.isSummary,
      path: this.$router.currentRoute.meta.breadcrumb,
      applications: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.meta.isApprover) {
        v.getApprovalApplications();
      } else {
        v.loadMyApplications();
      }
      /** get setups */
      v.getSetup();
    });
  },
  mounted() {},
  created() {},
  watch: {
    $route(to, from) {
      this.isApprover = to.meta.isApprover;
      this.path = to.meta.breadcrumb;
    },
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    isCalender: {
      get() {
        return this.$store.getters["leave/isCalender"];
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "isCalender",
          data: value,
        });
      },
    },
    listFilter: {
      get() {
        return this.$store.state.leave.listFilterValue;
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "listFilterValue",
          data: value,
        });
      },
    },
    filter: {
      get() {
        return this.$store.state.leave.filterValue;
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "filterValue",
          data: value,
        });
      },
    },
    totalPosted: function () {
      return this.applications?.filter(app => app.status === 'Posted').length;
    },
    totalOpen: function () {
      return this.applications?.filter(app => app.status === 'Open').length;
    },
    totalRejected: function () {
      return this.applications?.filter(app => app.status === 'Rejected').length;
    },
    totalReview: function () {
      return this.applications?.filter(app => app.status === 'Review').length;
    },
  },
  methods: {
    ...LeaveMethods,
    getApprovalApplications: function () {
      const userId = this.userData.profile.user_id;
      const data = { userId };
      this.$store.dispatch("leave/getApprovalApplications", data);
    },
    loadMyApplications: function () {
      const employeeNo = this.userData.employee;
      const data = { employeeNo };
      setTimeout(() => {
        this.$store.dispatch("leave/getApplications", data);
      }, 1000);
    },
    deleteApplication: function (id) {
      this.$store.dispatch("leave/deleteApplication", {
        id: id,
        data: { Status: "Canceled" },
      });
    },
    handleApplications(newApplications) {
      this.applications = newApplications;  
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <!--BEGIN Tasks dialog-->
    <!--END Tasks dialog-->
    <v-form v-model="isValid" ref="HandoverForm">
      <v-autocomplete
        :items="handoverEmployees"
        placeholder="Hand Over To"
        persistent-placeholder
        :rules="handoverRequired ? rules : []"
        label="Hand Over To"
        clearable
        filled
        item-text="firstName"
        item-value="number"
        v-model="formData.hand_over_to"
        class="mt-1 leaveInputs"
        multiple
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeHandoverTo(data.item.number)"
          >
            <v-chip
              left
              x-small
              color="primary lighten-4"
              class="primary--text text--darken-4"
            >
              {{ data.item.number }}
            </v-chip>
            <span>{{ `${data.item.firstName} ${data.item.lastName}` }}</span>
            <span>{{ `${data.item.firstName} ${data.item.middleName}` }}</span>
          </v-chip>
        </template>
        <template v-slot:prepend-inner>
          <v-icon color="primary"> how_to_reg </v-icon>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item ripple v-on="on" v-bind="attrs">
            <v-avatar
              color="primary lighten-4"
              size="40"
              class="primary--text text--darken-4 mr-3"
            >
              {{ `${item.firstName[0] || ""}${item.lastName[0] || ""}` }}
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span>
                  {{ item.firstName + " " + item.lastName }}
                  <v-chip
                    left
                    x-small
                    color="primary lighten-4"
                    class="primary--text text--darken-4 mx-2"
                  >
                    {{ item.number }}
                  </v-chip>
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip
                style="position: absolute; right: 1em; top: 1em"
                small
                label
                color="primary lighten-4"
              >
                {{ item.jobTitle }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-text-field
        placeholder="Reason For Leave Handover"
        clearable
        filled
        v-model="formData.handoverDescription"
        class="mt-1 leaveInputs text-capitalize"
      >
      </v-text-field>
      <!-- <v-col
          cols="12"
          md="12"
          sm="12"
          class="mt-n4"
          v-if="company === 'KISEB'"
        >
          <div class="d-flex justify-space-between">
            <div>Handover Tasks</div>
            <div>
              <v-btn
                @click="handoverTaskDialog = true"
                color="primary"
                class="text-capitalize"
              >
                Add Tasks
                <v-icon class="mx-2">add</v-icon>
              </v-btn>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="formData.handoverTasks"
            class="elevation-0 mb-2"
            fixed-header
          >
            <template v-slot:item.no="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="removeTask(item)"
                color="deep-orange darken-2"
                class="text-capitalize white--text"
                small
              >
                Remove Task
                <v-icon class="mx-2">delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col> -->
    </v-form>
  </div>
</template>
<script>
export default {
  name: "HandoverForm",
  props: {
    leaveEmployees: Array,
    hand_over: String,
    displayHandover: String,
    handoverRequired: Boolean,
    rules: Array,
  },
  data: function () {
    return {
      isValid: false,
      isHandOverTaskValid: false,
      handoverTaskDialog: false,
      tasksCounter: 0,
      hand_over_to: "",
      formData: {
        hand_over_to: [],
        handoverDescription: "",
        handoverTasks: [],
      },
      handoverTaskStatus: [
        {
          title: "Open",
          value: "open",
        },
        {
          title: "In Progress",
          value: "ongoing",
        },
      ],
      company: process.env.VUE_APP_COMPANY,
    };
  },
  computed: {
    /** mock UI data */
    tasks: function () {
      return [
        {
          task: "Task1",
          status: "In Progress",
        },
      ];
    },
    headers: function () {
      return [
        {
          text: "#No",
          align: "start",
          sortable: true,
          value: "no",
        },
        { text: "task", sortable: true, value: "task" },
        { text: "status", sortable: true, value: "status" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    handoverReportTemplate: function () {
      return this.$store.getters["leave/leaveGetter"]("handoverReportTemplate");
    },
    handoverReasons() {
      return this.$store.getters["handover/handoverGetter"]("handoverReasons");
    },
    handoverEmployees() {
      return this.leaveEmployees
        ? this.leaveEmployees.filter((d) => d.receiveHandover === true)
        : [];
    },
  },
  methods: {
    // add task to formData
    addHandoverTask() {
      this.tasksCounter++;
      this.formData.handoverTasks.push({
        id: this.tasksCounter,
        task: this.formData.handoverTasks.task,
        status: this.formData.handoverTasks.status,
      });
      this.handoverTaskDialog = false;
      this.$emit("handoverUpdated", this.formData);
    },
    // rm task
    removeTask(task) {
      this.formData.handoverTasks.splice(
        this.formData.handoverTasks.findIndex((value) => value.id === task.id),
        1
      );
      this.$emit("handoverUpdated", this.formData);
    },

    removeHandoverTo(number) {
      const index = this.formData.hand_over_to.indexOf(number);
      if (index >= 0) this.formData.hand_over_to.splice(index, 1);
    },
  },
  watch: {
    "formData.hand_over_to": {
      handler(n, o) {
        this.$emit("handoverUpdated", this.formData);
      },
    },
    "formData.handoverDescription": {
      handler(n, o) {
        this.$emit("handoverUpdated", this.formData);
      },
    },
  },
};
</script>

import { membershipLinks } from "./Modules/membership";

const reportLinks = [...membershipLinks];

export default {
  order: 10,
  name: "Reports",
  icon: "mdi-file-powerpoint",
  to: "/reporting/Dashboard",
  links: reportLinks,
  hasSideLinks: true,
  enabled: false,
};

export default {
  periods: "hrmis/appraisal/periods",
  templates: "hrmis/appraisal/template",

  acceptance: "hrmis/appraisal/periods/stages/accept",
  // endpoint jds & guideline -> (text and report)
  JDS: (params) => `hrmis/appraisal/jds?${getParams(params)}`,
  getJDSReport: `cu/HRMIS_CU/getJDreport`, //params jobCode:""
  APPRAISALGUIDELINE: `hrmis/appraisal/guideline`,
  //employee: (no) => `hrmis/employee?no=${no}`,
  jobs: (params) => `hrmis/appraisal/jobs?${getParams(params)}`,

  /* appraisal app endpoints*/
  createApplication: "hrmis/appraisal/v3/template/createApplication",
  getApplications: "hrmis/appraisal/v3/template/allApplications",
  SINGLEAPP: (no) => `hrmis/appraisal/v3/template/application/${no}`,
  employeeGroups: `hrmis/appraisal/v3/template/employee-groups/`,
  employeeAppObjectives: `hrmis/appraisal/v3/template/app-objectives/`,
  appKpis: `hrmis/appraisal/v3/template/app-kpis/`,
  deleteLine: "hrmis/appraisal/v3/template/deleteLine",
  getApplicationLines: (id) =>
    `hrmis/appraisal/v3/template/getApplicationLines/${id}`,
  editApplication: "hrmis/appraisal/v3/template/editApplicationLines",

  deleteApplication: `hrmis/appraisal/v2/template/deleteApp`,

  objectiveCodes: "hrmis/appraisal/v2/template/objectiveCodes",

  /**approvals */
  sendForApproval: "hrmis/appraisal/v2/template/sendForApproval",
  cancelForApproval: "hrmis/appraisal/v2/template/cancelForApproval",

  archivedReviewDocument: (params) =>
    `hrmis/appraisal/v3/archived_review_docs?${getParams(params)}`,
  getReviewDocument: (params) =>
    `cu/GALAXY_CU/getReviewDocument?${getParams(params)}`,

  reviewDocument: (param) => `hrmis/appraisal/review/${param}`,

  // ratingscale
  ratingScale: "hrmis/appraisal/rating-scale",

  //reviews
  reviews: "hrmis/appraisal/review",
  submitAppraisal: "hrmis/appraisal/review/submitAppraisal",
  supervisorReviews: "hrmis/appraisal/review/peer-reviews",
  secondSupervisorReviews: "hrmis/appraisal/review/second-peer-reviews",
  reviewees: "hrmis/appraisal/review/reviewer",
  kpi: "hrmis/appraisal/review/kpi",
  reviewObj: "hrmis/appraisal/review/reviewObj",
  appraisalReport: "hrmis/appraisal/review/reviewDocument",
  supervisorReport: "hrmis/appraisal/review/appraisedDocuments",
  acceptReview: "hrmis/appraisal/v2/template/acceptReview",
  rejectReview: "hrmis/appraisal/v2/template/rejectReview",
  REVIEWER_COMMENT: "hrmis/appraisal/review/review-comment",
  reviewAppraisal: "hrmis/appraisal/review/reviewAppraisal",

  pendingApprovals: "hrmis/appraisal/v2/template/pendingApprovals",
  approve: "approvals/approve",
  reject: "approvals/reject",
  appEntries: (id) => `approvals/approvalEntries?documentNo=${id}`,
};

function getParams(object) {
  return Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&")
    .toString();
}

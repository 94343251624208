import call from "@kinetics254/cassandra-base/service/http";
import constants from "./SetupConstants";

export default {
  namespaced: true,
  state: {
    allocations: [],
    leavePeriod: [],
    leaveTypes: [],
    approvers: [],
    ledgerEntries: [],
    handoverSetup: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    setupGetter: (state) => (setup) => state[setup],
  },
  actions: {
    getAllocations: ({ commit }, params) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.allocations(params))
        .then((response) => {
          commit("MUTATE", { state: "allocations", data: response.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log("allocation error", error.response.data.message);
        });
    },
    getLeavePeriod: async function () {
      return call("get", constants.leavePeriod);
    },
    getLeaveTypes: ({ commit }) => {
      call("get", constants.leaveTypes)
        .then((response) => {
          commit("MUTATE", { state: "leaveTypes", data: response.data.data });
        })
        .catch((error) => {
          console.log("leave types error", error.response.data.message);
        });
    },
    getLedgerEntries: ({ commit }, id) => {
      call("get", constants.ledgerEntries(id))
        .then((response) => {
          commit("MUTATE", {
            state: "ledgerEntries",
            data: response.data.data,
          });
        })
        .catch((error) => {
          console.log("ledger entries error", error.response.data.message);
        });
    },
    getHandoverSetup: ({ commit }) => {
      call("get", constants.handoverSetup)
        .then((response) => {
          commit("MUTATE", {
            state: "handoverSetup",
            data: response.data.data,
          });
        })
        .catch((error) => {
          console.log("handover setup error", error.response.data.message);
        });
    },
  },
};

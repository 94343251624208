import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"isApprover":_vm.isApprover,"path":_vm.path,"leaveapprovalSummary":_vm.leaveapprovalSummary}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('div',[_c(VTextField,{attrs:{"prepend-inner-icon":"filter_alt","solo":"","dense":"","label":"filter"},model:{value:(_vm.listFilter),callback:function ($$v) {_vm.listFilter=$$v},expression:"listFilter"}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VBadge,{staticClass:"mx-2 px-2",attrs:{"bordered":"","overlap":"","content":_vm.totalOpen,"color":"secondary","offset-x":"19","offset-y":"16"}},[_c(VBtn,{staticClass:"primary text-capitalize"},[_vm._v(" Total Open ")])],1)],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VBadge,{staticClass:"mx-2 px-2",attrs:{"bordered":"","overlap":"","content":_vm.totalReview,"color":"secondary","offset-x":"19","offset-y":"16"}},[_c(VBtn,{staticClass:"primary text-capitalize"},[_vm._v(" Total Review ")])],1)],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VBadge,{staticClass:"mx-2 px-2",attrs:{"bordered":"","overlap":"","content":_vm.totalPosted,"color":"secondary","offset-x":"19","offset-y":"16"}},[_c(VBtn,{staticClass:"primary text-capitalize"},[_vm._v(" Total Posted ")])],1)],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VBadge,{staticClass:"mx-2 px-2",attrs:{"bordered":"","overlap":"","content":_vm.totalRejected,"color":"secondary","offset-x":"19","offset-y":"16"}},[_c(VBtn,{staticClass:"primary text-capitalize"},[_vm._v(" Total Rejected ")])],1)],1)],1),_c('div',{staticStyle:{"height":"100%"}},[(_vm.isCalender)?_c('LeaveCalender',{attrs:{"isApprover":_vm.isApprover}}):_vm._e(),_c('ApprovalSummary',{attrs:{"isApprover":_vm.isApprover,"leaveapprovalSummary":_vm.leaveapprovalSummary},on:{"fetchedApplications":_vm.handleApplications}})],1),_c('LeaveSheet')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
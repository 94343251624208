<template>
  <v-app-bar
    class="leaveBar mb-2"
    dense
    style="background-color: #f0f3f4"
    :style="{ border: '0' }"
  >
    <div class="text-lg-h5" style="font-weight: 300">
      {{ appbarTitle }}
    </div>
    <v-spacer></v-spacer>
    <v-breadcrumbs
      customDivider
      divider="/"
      large
      :items="path"
    ></v-breadcrumbs>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBar",
  props: { isApprover: Boolean, path: Array, leaveapprovalSummary: Boolean },
  data: function () {
    return {
      Approver: this.isApprover,
      approverSummary: this.leaveapprovalSummary,
    };
  },

  computed: {
    appbarTitle() {
      if (this.Approver === true && this.approverSummary === true) {
        return "Leave Approval Summary";
      } else if (this.Approver === true) {
        return "Leave Approval";
      } else {
        return "Leave Application";
      }
    },
  },
};
</script>

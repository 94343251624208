import AppDashboard from "../../app/layout/AppDashboard";
import Dashboard from "./views/Dashboard";
import Auth from "../auth/middleware/Auth";

export default [
  {
    path: "/dashboard",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/dashboard/main",
      },
      {
        path: "main",
        components: {
          view: Dashboard,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "Dashboard", disabled: false, href: "/dashboard/main" },
          ],
        },
      },
      {
        path: "job-description:position",
        name: "JobDescription",
        components: {
          view: () => import("./components/JobDescription"),
        },
      },
      {
        path: "contract",
        name: "Contract",
        components: {
          view: () => import("./components/Contract"),
        },
      },
      {
        path: "contract/document/:contractNo",
        name: "ContractDocument",
        components: {
          view: () => import("./components/contracts/viewContract.vue"),
        },
      },
      {
        path: "contract/letter/:contractNo",
        name: "ContractLetter",
        components: {
          view: () => import("./components/contracts/viewContractLetter.vue"),
        },
      },
    ],
  },
];

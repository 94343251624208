export default {
  order: 2,
  name: "Leave",
  icon: "date_range",
  to: "/leave/dashboard",
  hasSideLinks: true,
  enabled: true,
  links: [
    {
      name: "Leave Dashboard",
      icon: "date_range",
      to: "/leave/dashboard",
      enabled: true,
      hasSubs: undefined,
    },
    {
      name: "Application",
      icon: "pending_actions",
      to: "/leave/application",
      enabled: true,
      hasSubs: undefined,
    },
    {
      name: "Leave Approval",
      icon: "event_available",
      to: "/leave/approvals",
      enabled: true,
      hasSubs: undefined,
    },
    {
      name: "Leave Planner",
      icon: "insert_invitation",
      to: "/leave/planner",
      enabled: true,
    },
    {
      name: "Approval Summary",
      icon: "event_available",
      to: "/leave/approval-summary",
      enabled: true,
      hasSubs: undefined,
    },
    // {
    //   name: "Handover Requests",
    //   icon: "file_copy",
    //   to: "/handover/handOverRequests",
    // },
  ],
};

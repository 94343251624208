import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@/packages/cassandra-base/app/layout/AppDashboard");

import Disciplinary from "./views/Disciplinary";
export default [
  {
    path: "/disciplinary",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: {
          view: Disciplinary,
        },
        children: [
          {
            path: "raised",
            name: "Raised List",
            components: {
              view: () => import("./components/RaisedList"),
            },
            meta: {
              middleware: [Auth],
            },
          },
          {
            path: "accused",
            name: "Accused List",
            components: {
              view: () => import("./components/AccusedList"),
            },
            meta: {
              middleware: [Auth],
            },
          },
          {
            path: "card/:no?",
            name: "DisciplinaryCard",
            components: {
              view: Disciplinary,
              action: () => import("./components/DisciplinaryCard"),
            },
            meta: {
              middleware: [Auth],
            },
          },
        ],
      },
    ],
  },
];
